import BlogItem from '@/components/BlogItem';
import CustomAntCarousel from '@/components/CustomAntCarousel';
import useWidth, { IBooleanArgs } from '@/helpers/widthUtil';
import { Button } from 'antd';
import Link from 'next/link';
import { memo } from 'react';

function BlogCardSection({ blogs }) {
  const { isMobile }: IBooleanArgs = useWidth();

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };
  return (
    blogs?.length !== 0 && (
      <section className="section bg-light">
        <div className="custom-container">
          <div className="section-title">
            <h3>Latest Blog</h3>
          </div>
          <div className="more-card mb-0 d-flex justify-content-end">
            <Link href="/blog" prefetch={false}>
              <a>
                <Button
                  size="middle"
                  className="btn-danger text-white border-14 border-danger btn-sm pl-2 pr-2 montserrat-font-family"
                >
                  View all Blogs <i className="fas fa-arrow-right ml-1"></i>
                </Button>
              </a>
            </Link>
          </div>
          <div className="d-none d-lg-block">
            <div className="row">
              {blogs?.map((data, idx) => (
                <BlogItem key={idx} data={data} />
              ))}
            </div>
          </div>
          <div className="d-sm-block d-md-block d-lg-none">
            <CustomAntCarousel customSettings={settings}>
              {blogs?.map((data, idx) => (
                <div
                  className={`${isMobile ? 'd-flex justify-content-center' : ''}`}
                  key={data.id + new Date().getMilliseconds() + Math.random()}
                >
                  <BlogItem data={data} />
                </div>
              ))}
            </CustomAntCarousel>
          </div>
        </div>
      </section>
    )
  );
}

export default memo(BlogCardSection);