import React, { memo } from 'react';
import Link from 'next/link';
import { Button } from 'antd';
import CustomCarousel from '../../CustomCourseCarousel';

function CourseCardSection({ courses }) {
  return courses?.length !== 0 ? (
    <section className="card-section bg-light">
      <div className="custom-container">
        <div className="section-title">
          <h3>Our Popular Courses</h3>
        </div>
        <div className="more-card d-flex justify-content-end">
          <Link href="/course" prefetch={false}>
            <a>
              <Button
                size={'middle'}
                className="btn-danger text-white border-14 border-danger btn-sm pl-2 pr-2 montserrat-font-family"
              >
                View all courses <i className="fas fa-arrow-right ml-1"></i>
              </Button>
            </a>
          </Link>
        </div>
        <div className="row">
          <CustomCarousel courses={courses} isLandingCourseSection />
        </div>
      </div>
    </section>
  ) : null;
}

export default memo(CourseCardSection);
