import useWidth from '@/helpers/widthUtil';
import Image from 'next/image';
import React, { memo } from 'react';

const FeaturesContent = ({ work }) => {
  const { isMobile } = useWidth();
  return work?.section_info?.length !== 0 ? (
    <section className="card-section">
      <div className="custom-container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12">
            <div className="features-content-area">
              <h3 className={`${isMobile ? 'mt-0 mb-0' : ''}`}>{work?.title}</h3>
              <p
                className={`custom-display-text custom-font-fw500-1314 ${
                  isMobile ? 'text-center' : ''
                }`}
              >
                {work?.sub_title}
              </p>
              <div className="banner-image p-4 mt-2">
                <span>
                  <Image
                    // src="/images/saas-image/reducedimage.webp"
                    src="/images/how.webp"
                    alt="image"
                    width="300px"
                    height="200px"
                    className="tranform-up-hover box-shadow-hover"
                    priority
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-7 col-md-12">
            <div className="row justify-content-end">
              {work?.section_info?.map((data, key) => (
                <div className="col-lg-5 col-md-7 col-sm-6" key={`feature-${key}`}>
                  <div
                    className={`single-features-item border-14 tranform-up-hover bg-${key}`}
                  >
                    <div className="icon">
                      <i className="flaticon-seo">
                        <i className={data?.icon}></i>
                      </i>
                    </div>
                    <h5 className="mt-2">{data.title.toUpperCase()}</h5>
                    <p className="text-dark font-weight-normal">
                      {data.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : null;
};

export default memo(FeaturesContent);
