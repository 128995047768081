import { getHome } from '@/actions/home';
import APIServices from '@/apiUtils/APIServices';
import Blog from '@/components/landing/home/Blog';
import Course from '@/components/landing/home/Course';
import FeaturesContent from '@/components/landing/home/Feature';
import LandingLayout from '@/components/landing/home/LandingLayout';
import LandingTabs from '@/components/landing/home/LandingTabs';
import MainBanner from '@/components/landing/home/MainBanner';
import TestimonialSection from '@/components/landing/home/Testimonial';
import SEO from '@/components/landing/seo';
import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { FC, useEffect, useState } from 'react';
import {
  IBlog,
  ICourse,
  ILandingSection,
  ISeo,
  ITestimonial,
} from 'src/interfaces/landing/landingSection';
import { getUnauthorizedPartnerDetail, setData } from '@/actions/auth';

type IHomeProps = {
  data: {
    data: {
      landing_section: Array<ILandingSection>;
      seo: ISeo;
      mission: string;
      course: Array<ICourse>;
      testimonial: Array<ITestimonial>;
      blog: Array<IBlog>;
    };
  };
};

const Index: FC<IHomeProps> = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const home: IHomeProps['data']['data'] = props?.data?.data;
  const [howItWork, setHowItWork] = useState(null);
  const [experience, setExperience] = useState(null);
  const homeReducer = useSelector((state: any) => state?.home?.home);

  useEffect(() => {
    const auth = window.localStorage.getItem('auth');
    const parsedAuth = auth && auth !== 'undefined' ? JSON.parse(auth) : null;
    const isLoggedIn = parsedAuth?.isLoggedIn;
    if (isLoggedIn) {
      router.replace({
        pathname: router.pathname,
        query: {},
      });
    }
  }, []);

  useEffect(() => {
    const auth = window.localStorage.getItem('auth');
    const parsedAuth = auth && auth !== 'undefined' ? JSON.parse(auth) : null;
    const isLoggedIn = parsedAuth?.isLoggedIn;
    if (!isLoggedIn && router?.query?.partner) {
      const slug = router?.query?.partner;
      window.localStorage.setItem('partner_code', JSON.stringify(slug));
      dispatch(getUnauthorizedPartnerDetail(slug.toString()));
    } else if (!router?.query?.partner) {
      // setNeedBanner(false);
      dispatch(
        setData({
          name: 'partner',
          key: 'data',
          value: {},
        })
      );
    }
  }, [router?.query?.partner]);

  useEffect(() => {
    if (homeReducer?.data?.length === 0) {
      dispatch(getHome());
    }
  }, [homeReducer]);

  useEffect(() => {
    const howItWorkData =
      home &&
      home?.landing_section?.length !== 0 &&
      home?.landing_section?.filter((item: any) => item.info_type === 'SECTIONONE');
    setHowItWork(howItWorkData && howItWorkData[0]);
    const experienceData =
      home &&
      home?.landing_section?.length !== 0 &&
      home?.landing_section?.filter((item: any) => item.info_type === 'SECTIONTWO');
    setExperience(experienceData && experienceData[0]);
  }, [home]);

  return (
    <>
      <SEO
        meta_title={home?.seo?.meta_title}
        meta_description={home?.seo?.meta_description}
        meta_keywords={home?.seo?.meta_keywords}
        photo={'https://s3.ap-south-1.amazonaws.com/learnsic.com/learnsic_cover.png'}
        canonical=""
      />
      <LandingLayout>
        <MainBanner
          mission={home?.mission}
          myLearnings={homeReducer?.data?.my_learnings}
        />
        <Course courses={home?.course} />
        {howItWork ? <FeaturesContent work={howItWork} /> : null}
        {experience ? (
          // <TabsContent experience={experience} />
          <LandingTabs experience={experience} />
        ) : null}
        <TestimonialSection testimonials={home?.testimonial} />
        <Blog blogs={home?.blog} />
      </LandingLayout>
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const { data, success } = await new APIServices(`/home/`).get();
  return {
    props: {
      data: success ? data : {},
    },
    revalidate: 300,
  };
};

export default Index;
