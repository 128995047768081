import React from 'react';
import Head from 'next/head';

function SEO({
  meta_title,
  meta_description,
  meta_keywords,
  photo,
  schemas = null,
  canonical,
}) {
  return (
    <>
      <Head>
        {meta_title ? <title>{meta_title}</title> : null}
        {meta_description ? (
          <meta name="description" content={meta_description.replace(/<[^>]+>/g, '')} />
        ) : null}
        {meta_description ? (
          <meta property="og:description" content={meta_description.replace(/<[^>]+>/g, '')} />
        ) : null}
        {meta_keywords ? <meta name="keywords" content={meta_keywords} /> : null}
        {photo ? <meta property="og:image" content={photo} /> : null}
        {meta_title ? <meta property="og:title" content={meta_title} /> : null}
        {photo ? <meta property="og:image:width" content="180" /> : null}
        {photo ? <meta property="og:image:height" content="110" /> : null}
        {meta_keywords ? <meta name="twitter:card" content={meta_keywords} /> : null}
        <meta name="twitter:url" content={`${process.env.NEXT_PUBLIC_SITE_URL}/`} />
        {meta_title ? <meta name="twitter:title" content={meta_title} /> : null}
        {meta_description ? (
          <meta name="twitter:description" content={meta_description} />
        ) : null}
        {photo ? <meta name="twitter:image" content={photo} /> : null}
        {schemas ? (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: schemas }}
          />
        ) : null}
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_SITE_URL}${canonical ? canonical : ''}`}
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" as="font" />
      </Head>
    </>
  );
}

export default SEO;
